import React, { useMemo } from 'react'
import { UgcSection } from '@heights/heights-ui-components'
import { withErrorBoundary } from '@/utils'
import { useCtaAction } from '@/hooks/useCtaAction'
import compact from 'just-compact'
import { transformImage } from '@/utils/contentful'
import { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { sectionDataHookFactory } from '@/hooks'
import { textItem } from '@/schemas'
import { asResolverQuery } from '@/utils/groq'

type Props = {
  data: SingleComponentPickerItem
}

const [textItemQuery, textItemType] = textItem.builder
  .map((item) => ({
    image: item.fields.image?.resolve(['_id', 'fields']).use(),
    authorImage: item.fields.authorImage?.resolve(['_id', 'fields']).use(),
    ctaAction: item.fields.ctaAction?.resolve(['_id', 'fields']).use(),
  }))
  .use()

const useSectionData = sectionDataHookFactory({
  textItems: asResolverQuery<typeof textItemType>(
    'fields.components',
    textItemQuery,
    '@->_type == "textItem"'
  ),
})

export const ContentfulSectionUgcSection: React.FunctionComponent<Props> =
  withErrorBoundary(({ data }) => {
    const section = useSectionData(data._id)
    const ctaAction = useCtaAction(
      section?.ctaActions?.[0] ?? section?.ctaAction
    )
    const posts = useMemo(
      () =>
        compact(
          section?.textItems?.map((textItem) => {
            const authorImage = transformImage(textItem?.authorImage?.fields, {
              width: 100,
              aspectRatio: 1,
              transformOptions: { radius: 50 },
            })
            const image = transformImage(textItem?.image?.fields, {
              width: 600,
              fullscreen: true,
              aspectRatio: 1,
              objectFit: 'cover',
              objectPosition: 'contain',
              sizes: '(max-width: 720px) 40vw, 100vw',
            })

            if (!textItem?.fields?.author) {
              return null
            }

            return {
              user: {
                username: textItem?.fields.author,
                avatar: authorImage,
              },
              text: textItem?.fields.plainText ?? '',
              image,
              platform: textItem.fields?.textlistValue?.[0],
              mediaPath: textItem?.ctaAction?.fields?.url,
            }
          }) ?? []
        ),
      [section]
    )

    return (
      <UgcSection
        title={section?.fields?.title ?? undefined}
        quote={section?.fields?.subtitle ?? undefined}
        backgroundColor={section?.fields?.backgroundColor ?? undefined}
        //@fix type
        posts={posts as any}
        cta={ctaAction ?? undefined}
      />
    )
  })

export default ContentfulSectionUgcSection
